import styled from 'styled-components';
import { Carousel } from 'antd';

const CarouselWrapper = styled(Carousel)`

  .slick-dots {
    margin-top: 20px;
    position: relative;

    li {
      background: grey;

      &.slick-active button {
        background: ${(props) => `${props.$colorPrimary}`};
      }
    }
  }

  .slick-dots-bottom {
    bottom: 0;
  }

  .slick-prev {
    font-size: inherit;
    color: ${(props) => `${props.$colorPrimary}`};
  }

  .slick-next {
    font-size: inherit;
    color: ${(props) => `${props.$colorPrimary}`};
  }

  .slick-track {
    text-align: center;
    display: flex;
    align-items: center;
  }
`;

export default CarouselWrapper;
