import React from 'react';
import { Typography } from 'antd';
import Stats from '../../components/Stats';
import Feedback from '../../components/Feedback';
import HomeExperts from '../../components/Experts/HomeExperts';

const { Title } = Typography;

const Home = function () {
  return (
    <>
      <Title level={2} style={{ marginBottom: 0, paddingTop: 24 }}>
        Meet our Community
      </Title>
      <Stats />
      <HomeExperts />
      <Feedback />
    </>
  );
};

export default Home;
